import React from 'react';
import { Typography } from '@mui/material';

const Heading = ({ children, ...rest }) => (
  <Typography variant='h3' component='h2' {...rest} textTransform='capitalize'>
    {children}
  </Typography>
);

export default Heading;
