import React, { useState } from 'react';
import { Link } from 'gatsby';
import { AppBar, Box, Drawer, IconButton, List, ListItem, Stack, styled } from '@mui/material';

import { Button, Icon } from 'ui';
import { navigationLinks } from 'utils/data';
import logoImg from 'images/logo.webp';

const Navigation = () => {
  const [openMobileNav, setOpenMobileNav] = useState();
  const toggleMobileNav = visibility => () => setOpenMobileNav(visibility);

  return (
    <>
      <NavBar>
        <Logo to='/'>
          <img src={logoImg} alt='LOGO' />
        </Logo>

        <NavList>
          {navigationLinks.map((navLink, idx) => (
            <NavListItem key={idx}>
              <Link to={navLink.href} activeClassName='nav-link-active'>
                {navLink.text}
              </Link>
            </NavListItem>
          ))}
        </NavList>

        <ActionButton endIcon={<Icon name='ArrowUpRight' />} component={Link} to='/enquire'>
          Enqure now
        </ActionButton>

        <Hamburger onClick={toggleMobileNav(true)}>
          <Icon name='Menu' color='primary.main' />
        </Hamburger>
      </NavBar>

      <Drawer open={openMobileNav} anchor='top' onClose={toggleMobileNav(false)}>
        <MobileNav>
          <Stack alignItems='flex-end' px={2}>
            <IconButton onClick={toggleMobileNav(false)}>
              <Icon name='X' color='primary.main' />
            </IconButton>
          </Stack>

          <List>
            {navigationLinks.map((navLink, idx) => (
              <MobileNavItem key={idx}>
                <Link
                  to={navLink.href}
                  target={navLink.target || '_self'}
                  activeClassName='nav-link-active'
                  onClick={toggleMobileNav(false)}>
                  {navLink.text}
                </Link>
              </MobileNavItem>
            ))}
          </List>
        </MobileNav>
      </Drawer>
    </>
  );
};

export default Navigation;

const NavBar = styled(AppBar)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  position: 'sticky',

  top: 0,
  zIndex: 9,
  alignItems: 'center',
  padding: theme.spacing(2, 7),

  backdropFilter: 'blur(16px) saturate(180%)',
  boxShadow: 'none',
  backgroundColor: 'rgba(240, 240, 240, 0.75)',
  color: theme.palette.common.black,
  textTransform: 'uppercase',
  fontSize: '.8rem',
  fontWeight: 800,

  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 2),
  },
}));

const NavList = styled('ul')(({ theme }) => ({
  listStyleType: 'none',
  display: 'flex',
  gap: theme.spacing(5),

  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const NavListItem = styled('li')(({ theme }) => ({
  position: 'relative',

  '&::before': {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '2px',
    borderRadius: theme.borderRadius.medium,
    backgroundColor: theme.palette.primary.main,
    left: 0,
    bottom: '-2px',
    transform: 'scaleX(0)',
    willChange: 'transform',
    transition: 'transform .3s',
    transformOrigin: 'left',
  },

  '&:hover::before': {
    transform: 'scaleX(1)',
  },
}));

const Logo = styled(Link)({
  display: 'flex',
  justifySelf: 'center',

  '& > img': {
    height: '3rem',
  },
});

const ActionButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const Hamburger = styled(IconButton)(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.down('md')]: {
    display: 'block',
  },
}));

const MobileNav = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.neutral.white,
  padding: theme.spacing(2, 0),
}));

const MobileNavItem = styled(ListItem)({
  display: 'flex',
  justifyContent: 'center',
  textTransform: 'capitalize',
});
