import React from 'react';
import { Chip, Container, Stack, Typography, styled } from '@mui/material';

const Section = ({ children, chip, title, id = null, sx = null, ...rest }) => (
  <StyledSection id={id} sx={sx}>
    <Container {...rest}>
      <Stack alignItems='center' textTransform='capitalize'>
        {chip && <Chip variant='outlined' label={chip} />}
        {title && (
          <Typography variant='h3' mb={5} component='h2' textAlign='center'>
            {title}
          </Typography>
        )}
      </Stack>

      {children}
    </Container>
  </StyledSection>
);

export default Section;

const StyledSection = styled('section')(({ theme }) => ({
  padding: theme.spacing(10, 0),

  '& > .MuiContainer-root': {
    maxWidth: '90vw',
  },
}));
