import React from 'react';
import { Button, styled } from '@mui/material';

const ButtonWrapper = ({ children, variant = 'primary', ...rest }) => {
  switch (variant) {
    case 'primary':
      return (
        <ButtonPrimary variant='contained' {...rest}>
          {children}
        </ButtonPrimary>
      );

    case 'secondary':
      return (
        <ButtonSecondary variant='outlined' {...rest}>
          {children}
        </ButtonSecondary>
      );

    default:
      return (
        <ButtonBase variant='contained' {...rest}>
          {children}
        </ButtonBase>
      );
  }
};

export default ButtonWrapper;

const ButtonBase = styled(Button)(({ theme }) => ({
  borderRadius: theme.borderRadius.medium,
  boxShadow: 'none !important',
  textTransform: 'initial',
  position: 'relative',
  zIndex: 1,
  padding: theme.spacing(1, 3.5),
}));

const ButtonPrimary = styled(ButtonBase)(({ theme }) => ({
  color: theme.palette.common.white,

  '&::after': {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'inherit',
    borderRadius: 'inherit',
    top: 0,
    left: 0,
    transition: 'all .3s',
    zIndex: -1,
  },

  '&:hover::after': {
    transform: 'scaleX(1.4) scaleY(1.6)',
    opacity: 0,
  },

  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
}));

const ButtonSecondary = styled(ButtonBase)(({ theme }) => ({
  border: `2px solid ${theme.palette.primary.main}`,
  backgroundColor: theme.palette.grey.A100,

  '&:hover': {
    borderWidth: '2px',
  },

  '& .MuiIcon-root svg': {
    stroke: `${theme.palette.primary.main} !important`,
  },
}));
