import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      light: '#ffe8da',
      main: '#ff6600',
    },
    neutral: {
      black: '#313437',
      white: '#F7F8Fc',
      grey: '#718096',
      lightgrey: '#E4E6F0',
    },
  },
  typography: {
    fontFamily: `'Manrope', sans-serif`,
  },

  borderRadius: {
    tiny: '.8rem',
    small: '2rem',
    medium: '2rem',
  },

  boxShadow: {
    light: '0 44px 24px rgba(237,239,241,.15)',
    medium: '0 1rem 2rem rgba(0,0,0,0.15)',
  },
});

export default theme;
