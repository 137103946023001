import client1Img from 'images/clients/1.webp';
import client2Img from 'images/clients/2.webp';
import client3Img from 'images/clients/3.webp';
import client4Img from 'images/clients/4.webp';
import client5Img from 'images/clients/5.webp';
import client6Img from 'images/clients/6.webp';
import client7Img from 'images/clients/7.webp';
import client8Img from 'images/clients/8.webp';
import client9Img from 'images/clients/9.webp';
import client10Img from 'images/clients/10.webp';
import client11Img from 'images/clients/11.webp';
import client12Img from 'images/clients/12.webp';
import client13Img from 'images/clients/13.webp';
import client14Img from 'images/clients/14.webp';
import client15Img from 'images/clients/15.webp';
import client16Img from 'images/clients/16.webp';
import client17Img from 'images/clients/17.webp';
import client18Img from 'images/clients/18.webp';
import client19Img from 'images/clients/19.webp';
import client20Img from 'images/clients/20.webp';
import client21Img from 'images/clients/21.webp';

import about1Img from 'images/about/about-1.webp';
import about2Img from 'images/about/about-2.webp';
import about3Img from 'images/about/about-3.webp';
import about4Img from 'images/about/about-4.webp';

import galleryImg1 from 'images/gallery/1.webp';
import galleryImg2 from 'images/gallery/2.webp';
import galleryImg3 from 'images/gallery/3.webp';
import galleryImg4 from 'images/gallery/4.webp';
import galleryImg5 from 'images/gallery/5.webp';
import galleryImg6 from 'images/gallery/6.webp';
import galleryImg7 from 'images/gallery/7.webp';
import galleryImg8 from 'images/gallery/8.webp';
import galleryImg9 from 'images/gallery/9.webp';
import galleryImg10 from 'images/gallery/10.webp';
import galleryImg11 from 'images/gallery/11.webp';
import galleryImg12 from 'images/gallery/12.webp';
import galleryImg13 from 'images/gallery/13.webp';
import galleryImg14 from 'images/gallery/14.webp';
import galleryImg15 from 'images/gallery/15.webp';
import galleryImg16 from 'images/gallery/16.webp';
import galleryImg17 from 'images/gallery/17.webp';
import galleryImg18 from 'images/gallery/18.webp';

export const navigationLinks = [
  {
    text: 'home',
    href: '/',
  },
  {
    text: 'about',
    href: '/about',
  },

  {
    text: 'services',
    href: '/services',
  },
  {
    text: 'gallery',
    href: '/gallery',
  },
];

export const contact = {
  phone: '09898014997',
  email: 'gpel.baroda@gmail.com',
  location:
    'https://www.google.co.in/maps/place/Gujarat+Perfect+Engineering+Limited/@22.091848,73.1722156,17z/data=!4m5!3m4!1s0x0:0xa78a0441ecec6ca6!8m2!3d22.0925094!4d73.1755221?hl=en&shorturl=1',
  socials: {
    facebook: 'https://m.facebook.com/profile.php?id=100067354901502&ref=content_filter&_rdr',
    instagram: 'https://www.instagram.com/gpel13/',
  },
};

export const features = [
  {
    title: 'globally trusted',
    description: 'Largest facility in Gujarat',
    icon: 'Globe',
  },
  {
    title: 'certified',
    description: 'We are an ISO certified company',
    icon: 'Award',
  },
  {
    title: 'experienced',
    description: '40+ years of industrial experience',
    icon: 'Key',
  },
  {
    title: 'expertise',
    description: 'Experts in Heat Treatment Facilities',
    icon: 'Sun',
  },
];

export const faqs = [
  {
    id: 0,
    question: 'How many years of experience does GPEL have in Heat Treatment?',
    answer:
      'GPEL has been specializing in Heat Treatment for over 40+ years, providing comprehensive solutions for a wide range of equipment.',
  },
  {
    id: 1,
    question: 'What types of jobs does GPEL undertake?',
    answer:
      "GPEL undertakes jobs under the inspection of various reputable organizations, including IRB (Inspecting Authority for Radiography and Broadcasting), LRIS (Lloyd's Register Inspection Services), BVIS (Bureau Veritas Industrial Services), NPCIL (Nuclear Power Corporation of India Limited), TUV (Technischer Überwachungsverein), and FEDO, among others.",
  },
  {
    id: 2,
    question: 'Do you offer any additional services?',
    answer:
      'In addition to our core services, we provide a range of additional services such as sand blasting, spray painting, DP (Dye Penetrant) testing, and picking passivation.',
  },
];

export const clients = [
  client1Img,
  client2Img,
  client3Img,
  client4Img,
  client5Img,
  client6Img,
  client7Img,
  client8Img,
  client9Img,
  client10Img,
  client11Img,
  client12Img,
  client13Img,
  client14Img,
  client15Img,
  client16Img,
  client17Img,
  client18Img,
  client19Img,
  client20Img,
  client21Img,
];

export const furnaces = [
  {
    maxTemp: 700,
    length: 15,
    width: 5,
    height: 5,
    weight: 100,
    remarks: 'approved by IBR, LRIS, BVIS',
  },
  {
    maxTemp: 1200,
    length: 5,
    width: 5,
    height: 2.5,
    weight: 20,
    remarks: 'approved by IBR, LRIS, BVIS',
  },
  {
    maxTemp: 1100,
    length: 12,
    width: 3.5,
    height: 3.5,
    weight: 30,
    remarks: 'approved by IBR, LRIS, BVIS',
  },
  {
    maxTemp: 1100,
    length: 1.5,
    width: 1.2,
    height: 1,
    weight: 1,
    remarks: 'approved by IBR, LRIS, BVIS',
  },
  {
    maxTemp: 1100,
    length: 2.4,
    width: 2.4,
    height: 1.2,
    weight: 2,
    remarks: 'approved by IBR, LRIS, BVIS',
  },
];

export const services = [
  {
    title: 'stress relieving',
    description:
      'Stress relieving is a crucial heat treatment process performed post-welding (PWHT) or after intensive machining. It involves controlled heating and cooling cycles to mitigate the risk of cracking, specifically hydrogen-induced cracking, in weld joints. Additionally, this process aims to preserve the shape and dimensions of the equipment over time.',
  },
  {
    title: 'Normalizing',
    description:
      'Normalizing is a technique employed to achieve uniformity in grain size and composition throughout steel. This process is applied to dished ends, both in post-fabrication stages and at intermediate stages, to enhance the overall structural integrity and performance of the material.',
  },
  {
    title: 'Solution Annealing',
    description:
      'Solution annealing is a meticulous heat treatment process wherein the metal is uniformly heated to its austenitic transformation temperature. Subsequently, rapid cooling is achieved through methods such as water spraying or forced air cooling.',
  },
  {
    title: 'Quench Tempering',
    description:
      'In the quench-tempering process, the metal undergoes a series of controlled steps. Initially, it is uniformly heated to its austenitic transformation temperature and rapidly cooled using methods such as water or forced air. Subsequently, the material is reheated to achieve the desired balance of hardness, ductility, toughness, strength, and structural stability.',
  },
  {
    title: 'Spinning Beam Cleaning',
    description:
      'Discover our Spinning Beam Cleaning Solution — a high-performance formula designed for effective and efficient maintenance. Extend the lifespan of your equipment with ease, ensuring optimal functionality. Elevate your maintenance routine today.',
  },
];

export const aboutSlides2D = [
  [about1Img, about2Img],
  [about3Img, about4Img],
];

export const aboutSlides1D = [about1Img, about2Img, about3Img, about4Img];

export const galleryImages = [
  galleryImg1,
  galleryImg2,
  galleryImg3,
  galleryImg4,
  galleryImg5,
  galleryImg6,
  galleryImg7,
  galleryImg8,
  galleryImg9,
  galleryImg10,
  galleryImg11,
  galleryImg12,
  galleryImg13,
  galleryImg14,
  galleryImg15,
  galleryImg16,
  galleryImg17,
  galleryImg18,
];

export const formTypes = {
  LONG_FORM: 'long-form',
  SHORT_FORM: 'short-form',
};
